import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {Link} from '@reach/router'
import {format} from 'date-fns'

import {PageLoader} from '../../components/elements/loader'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'

const VIEW_PURCHASE_ORDER_QUERY = gql`
  query ViewPurchaseOrder($purchase: ID!){
    purchaseOrder(id: $purchase){
      id
      xeroUUID
      orderNumber
      date
      deliveredDate
      supplier{
        id
        name
      }
      lines{
        quantity
        description
        serialNumbered
        product{
          id
          name
          productType{
            id
            name
          }
        }
        items{
          id
          received
          stockControlNumber
          purchasePrice
        }
      }
    }
  }
`

interface ViewPurchaseOrderResponse{
  purchaseOrder: {
    id: number
    xeroUUID: string
    orderNumber: string
    date: string
    deliveredDate?: string
    supplier: {
      id: number
      name: string
    }
    lines: {
      quantity: number
      description: string
      serialNumbered: boolean
      product: {
        id: number
        name: string
        productType: {
          id: number
          name: string
        }
      }
      items: {
        id: number
        received: boolean
        stockControlNumber: string
        purchasePrice: number
      }[]
    }[]
  }
}

export const ViewPurchaseOrder: React.FC<{path: string, purchase?: number}> = ({purchase}) => {
  const {loading, error, data} = useQuery<ViewPurchaseOrderResponse>(VIEW_PURCHASE_ORDER_QUERY, {variables: {purchase}})

  if(loading){
    return <PageLoader title="Purchase Order" message="Loading Purchase Order" />
  }

  if(error){
    console.dir(error.message)
    throw error
  }

  const {purchaseOrder} = data!

  return <Page>
    <Heading text={`Purchase Order ${purchaseOrder.orderNumber}`} actions={[]} />
    <Box cols={2}>
      PO #{purchaseOrder.orderNumber}<br />
      {purchaseOrder.supplier.name}<br />
      {format(new Date(purchaseOrder.date), 'dd/LL/yyyy')} {purchaseOrder.deliveredDate ? `Delivered: ${format(new Date(purchaseOrder.deliveredDate), 'dd/LL/yyyy')}` : ''}
    </Box>
    <Box cols={2}>
      <a href={`https://go.xero.com/Accounts/Payable/PurchaseOrders/View/${purchaseOrder.xeroUUID}`}>View on Xero</a>
    </Box>
    <Box cols={4}>
      <Table>
        <tbody>
          {purchaseOrder.lines.map((line, i) => {
            return <>
              <tr key={i}>
                <Cell rowSpan={line.quantity + 1} header>
                  <Link to={`/product_types/${line.product.productType.id}/${line.product.id}`}>{line.product.name}</Link><br />
                  &pound;{line.items[0].purchasePrice} ex VAT
                </Cell>
                <Cell colSpan={2}>{line.quantity}</Cell>
              </tr>
              {line.items.map((item) => {
                return <tr key={item.id}>
                  <Cell>{item.received ? 'Received' : 'Awaiting Delivery'}</Cell>
                  <Cell>{item.stockControlNumber}</Cell>
                </tr>
              })}
            </>
          })}
        </tbody>
      </Table>
    </Box>
  </Page>
}