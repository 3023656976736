import React from 'react'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Page, Heading} from '../layout/page'

export const Loader: React.FC<{message: string}> = ({message}) => {
  return <div className={`text-center w-full col-span-4 pt-64`}>
    <FontAwesomeIcon icon={faSpinner} spin className={`text-6xl mb-8`} />
    <p>{message}</p>
  </div>
}

export const PageLoader: React.FC<{message: string, title: string}> = ({message, title}) => {
  return <Page>
    <Heading text={title} actions={[]} />
    <Loader message={message} />
  </Page>
}