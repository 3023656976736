import React from 'react'
import {useQuery, useApolloClient} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {navigate} from '@reach/router'

import {PageLoader} from '../components/elements/loader'

import {Page, Heading, StatBox, Box} from '../components/layout/page'
import {Input} from '../components/blocks/form'

const DASHBOARD_QUERY = gql`
  query DashboardQuery{
    allProductTypes{
      count
    }
    allProducts{
      count
    }
    allPurchaseOrder(scope: RECEIVABLE){
      count
    }
    receivableItems: allItem(scope: RECEIVABLE){
      count
    }
    instockItems: allItem(scope: INSTOCK){
      count
    }
    deliveredItems: allItem(scope: DELIVERED){
      count
    }
  }
`

interface DashboardResponse{
  allProductTypes: {
    count: number
  }
  allProducts: {
    count: number
  }
  allPurchaseOrder: {
    count: number
  }
  receivableItems: {
    count: number
  }
  instockItems: {
    count: number
  }
  deliveredItems: {
    count: number
  }
}

const PO_LOOKUP_QUERY = gql`
  query POLookupQuery($number: String!){
    purchaseOrder(number: $number){
      id
    }
  }
`

const QUOTE_LOOKUP_QUERY = gql`
  query QuoteLookupQuery($number: String!){
    quote(number: $number){
      id
    }
  }
`

export const Dashboard: React.FC<{path: string}> = () => {
  const {query} = useApolloClient()
  const {loading, error, data} = useQuery<DashboardResponse>(DASHBOARD_QUERY)

  if(loading){
    return <PageLoader title="Warehouse" message="Loading Dashboard" />
  }

  if(error){
    throw error
  }

  const {allProductTypes, allProducts, allPurchaseOrder, receivableItems, instockItems, deliveredItems} = data!

  return <Page>
    <Heading text={"Warehouse"} actions={[]} />
    <StatBox cols={1} title={"Product Types"} stat={allProductTypes.count} to={`/product_types`} />
    <Box cols={3} rows={2}>
      <p>Welcome to Warehouse</p>
    </Box>
    <StatBox cols={1} title={"Products"} stat={allProducts.count} to={`/products`} />
    <StatBox cols={1} title={"Incoming POs"} stat={allPurchaseOrder.count} to={`/stock_control/incoming`} />
    <StatBox cols={1} title={"Incoming Items"} stat={receivableItems.count} to={`/stock_control/incoming`} />
    <StatBox cols={1} title={"Items in Stock"} stat={instockItems.count} to={`/stock_control/lookup`} />
    <StatBox cols={1} title={"Items with Customers"} stat={deliveredItems.count} to={`/stock_control/lookup`} />
    <Box cols={2}>
      <h4>PO Lookup</h4>
      <Input placeholder="PO-XXXX" onChange={async (e: any) => {
        const search = e.target.value
        
        if(search.length >= 5){
          const result = await query({
            query: PO_LOOKUP_QUERY,
            variables: {number: search}
          })

          if(result.data.purchaseOrder){
            navigate(`/purchase_orders/${result.data.purchaseOrder.id}`)
          }
        }
      }} />
    </Box>
    <Box cols={2}>
      <h4>Quote Lookup</h4>
      <Input placeholder="QU-XXXX" onChange={async (e: any) => {
        const search = e.target.value

        if(search.length >= 5){
          const result = await query({
            query: QUOTE_LOOKUP_QUERY,
            variables: {number: search}
          })

          if(result.data.quote){
            navigate(`/quote/${result.data.quote.id}`)
          }
        }
      }} />
    </Box>
  </Page>
}