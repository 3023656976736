import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {Link} from '@reach/router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconDefinition, faBoxes, faTachometerAlt, faShoppingCart, faReceipt, faBarcode, faUsers, faFileInvoice, faPallet, faSchool} from '@fortawesome/free-solid-svg-icons'

import {useUserActions} from '../../providers/user'

import {Notifications} from '../elements/notifications'

const HEADER_QUERY = gql`
  query HeaderQuery{
    me{
      id
      name
    }
  }
`

export const Layout: React.FC = ({children}) => {
  return <div className={`grid grid-cols-layout grid-rows-layout min-h-screen`}>
    <Notifications />
    <div className={`bg-dark-blue text-white py-3 px-3 shadow-sidebar`}>
      <img src="/logo.png" className={`w-8 float-left mr-8`} alt="Warehouse Logo" />
      <span className={`float-left text-2xl`}>Warehouse</span>
    </div>
    <HeaderStrip />
    <div className={`bg-dark-blue text-white py-3 px-3 shadow-sidebar`}>
      <SidebarHeading>Dashboards</SidebarHeading>
      <SidebarLink to={`/`} icon={faTachometerAlt}>Home</SidebarLink>
      <SidebarHeading>Products</SidebarHeading>
      <SidebarLink to={`/product_types`} icon={faBoxes}>Products</SidebarLink>
      <SidebarHeading>Stock Control</SidebarHeading>
      <SidebarLink to={`/stock_control/incoming`} icon={faShoppingCart}>Incoming</SidebarLink>
      <SidebarLink to={`/stock_control/lookup`} icon={faBarcode} >Lookup</SidebarLink>
      <SidebarLink to={`/stock_control/outgoing`} icon={faFileInvoice}>Outgoing</SidebarLink>
      <SidebarHeading>Contacts</SidebarHeading>
      <SidebarLink to={`/customers`} icon={faSchool}>Customers</SidebarLink>
      <SidebarLink to={`/suppliers`} icon={faPallet}>Suppliers</SidebarLink>
      <SidebarHeading>System</SidebarHeading>
      <SidebarLink to={`/xero_account`} icon={faReceipt}>Xero</SidebarLink>
      <SidebarLink to={`/users`} icon={faUsers}>Users</SidebarLink>
    </div>
    <div className={`p-3`}>{children}</div>
    <div className={`bg-dark-blue shadow-sidebar`}></div>
    <div className={`border-t border-gray-200 pt-4 pr-4 text-right`}>&copy; Ed-IT Solutions</div>
  </div>
}

const SidebarHeading: React.FC = ({children}) => {
  return <h2 className={`text-dark-blue-pale`}>{children}</h2>
}

const SidebarLink: React.FC<{to: string, icon: IconDefinition}> = ({children, to, icon}) => {
  return <Link to={to} className={`
    text-blue-gray hover:text-white
    hover:bg-pale-blue
    p-2 block rounded-sm m-1
  `}>{children} <FontAwesomeIcon icon={icon} className={`float-right leading-10 text-2xl`} /></Link>
}

const HeaderStrip: React.FC = () => {
  const {loading, error, data} = useQuery(HEADER_QUERY)
  const {logout} = useUserActions()

  const content = () => {
    if(loading){
      return <>loading</>
    }

    if(error){
      return <>Error with header query</>
    }

    const {me} = data

    return <><Link to='/users/me'>{me.name}</Link> <button onClick={() => logout()}>Logout</button></>
  }

  return <div className={`border-b border-gray-200 pt-4 pr-4 text-right`}>
    {content()}
  </div>
}