import React, {useState} from 'react'
import {useForm} from 'react-hook-use-form'
import gql from 'graphql-tag'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {Link} from '@reach/router'

import {Page, Box, Heading} from '../../components/layout/page'
import {Form, Input, Submit, Label} from '../../components/blocks/form'
import {Button} from '../../components/blocks/button'

export const StockLookup: React.FC<{path: string}> = () => {
  const [stockControlNumber, setStockControlNumber] = useState('')
  const {formBind, bind, onSubmit} = useForm({
    stockControlNumber: ''
  })

  onSubmit(({stockControlNumber}) => {
    setStockControlNumber(stockControlNumber)
  })

  return <Page>
    <Heading text="Stock Lookup" actions={[]} />
    <Box cols={2}>
      <Form {...formBind()}>
        <Label>Barcode</Label>
        <Input {...bind('stockControlNumber')} />
        <Submit label="Lookup" />
      </Form>
    </Box>
    {stockControlNumber !== '' ? <LookupResult stockControlNumber={stockControlNumber} /> : ''}
  </Page>
}

const ITEM_LOOKUP_QUERY = gql`
  query ItemLookup($stockControlNumber: String!){
    item(stockControlNumber: $stockControlNumber){
      id
      stockControlNumber
      received
      delivered
      removedFromStock
      product{
        id
        name
        productType{
          id
          name
        }
      }
      purchaseOrder{
        id
        orderNumber
      }
      quotes{
        id
        returned
        quote{
          id
          quoteNumber
        }
      }
    }
  }
`

interface ItemLookupResponse{
  item: {
    id: number
    stockControlNumber: string
    received: boolean
    delivered: boolean
    removedFromStock: boolean
    product: {
      id: number
      name: string
      productType: {
        id: number
        name: string
      }
    }
    purchaseOrder?: {
      id: number
      orderNumber: string
    }
    quotes: {
      id: number
      returned: boolean
      quote: {
        id: number
        quoteNumber: string
      }
    }[]
  }
}

const REMOVE_ITEM_FROM_STOCK = gql`
  mutation RemoveItemFromStock($item: ID!){
    removeItemFromStock(id: $item){
      id
    }
  }
`

const LookupResult: React.FC<{stockControlNumber: string}> = ({stockControlNumber}) => {
  const {loading, error, data, refetch} = useQuery<ItemLookupResponse>(ITEM_LOOKUP_QUERY, {variables: {stockControlNumber}})
  const [removeItemFromStock] = useMutation(REMOVE_ITEM_FROM_STOCK)

  if(loading){
    return <Box cols={4}>
      Loading...
    </Box>
  }

  if(error){
    return <Box cols={4}>
      Could not find Item with SCN {stockControlNumber}
    </Box>
  }

  const {item} = data!

  if(!item){
    return <></>
  }

  const purchase = () => {
    if(item.purchaseOrder === null){
      return 'Manually Added'
    }

    return <>
      <Link to={`/purchase_orders/${item.purchaseOrder!.id}`}>PO {item.purchaseOrder!.orderNumber}</Link>
    </>
  }

  const status = () => {
    if(item.removedFromStock){
      return 'Removed from Stock'
    }

    if(item.received && !item.delivered){
      return <>
        In Stock<br />
        <Button color={`red-300`} onClick={async () => {
          await removeItemFromStock({
            variables: {
              item: item.id
            }
          })
          refetch()
        }}>Remove from Stock</Button>
      </>
    }

    if(item.delivered){
      return 'Delivered'
    }
  }

  const sales = () => {
    if(item.quotes.length === 0){
      return ''
    }

    return <ul>
      {item.quotes.map((line) => {
        return <li key={line.id}>
          <Link to={`/quote/${line.quote.id}`}>{line.quote.quoteNumber} {line.returned ? '(Returned)' : ''}</Link>
        </li>
      })}
    </ul>
  }

  return <Box cols={4}>
    <h3>Item #{item.stockControlNumber}</h3>
    <Page>
      <p>
        <Link to={`/product_types/${item.product.productType.id}/${item.product.id}`}>{item.product.name}</Link><br />
        <Link to={`/product_types/${item.product.productType.id}`}>{item.product.productType.name}</Link>
      </p>
      <p>
        {purchase()}
      </p>
      <p>
        {status()}
      </p>
      <p>
        {sales()}
      </p>
    </Page>
  </Box>
}