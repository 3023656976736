import React from 'react'

import {Button} from '../blocks/button'

export const Modal: React.FC<{
  onClose: () => void
  title: string
}> = ({children, title, onClose}) => {
  return <div className="bg-black bg-opacity-50 fixed inset-0">
    <div className="bg-white w-1/2 mx-auto mt-32 rounded-lg">
      <div className="border-b p-2">{title}</div>
      <div className="p-2 overflow-y-scroll h-96">{children}</div>
      <div className="border-t p-2 text-right">
        <Button color="yellow-300" onClick={onClose}>Close</Button>
      </div>
    </div>
  </div>
}