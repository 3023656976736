import React from 'react'
import ApolloClient from 'apollo-client'
import {HttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {ApolloProvider} from '@apollo/react-hooks'

import {useUser} from '../../providers/user'

export const Apollo: React.FC = ({children}) => {
  const {token} = useUser()

  let uri = ''
  switch(process.env.NODE_ENV){
    case 'production':
      uri = 'https://warehouse.ed-it.solutions/graphql'
      break;
    case 'development':
    case 'test':
      uri = 'http://localhost:3000/graphql'
      break;
  }

  const link = new HttpLink({
    uri,
    headers: {
      token
    }
  })
  const cache = new InMemoryCache()

  const client = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      },
      query: {
        fetchPolicy: 'network-only'
      }
    }
  })

  return <ApolloProvider client={(client as any)}>
    {children}
  </ApolloProvider>
}