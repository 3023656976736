import React from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useForm} from 'react-hook-use-form'

import {PageLoader} from '../../components/elements/loader'
import {Page, Heading, Box} from '../../components/layout/page'
import {Form, Label, Input, Submit} from '../../components/blocks/form'

const CURRENT_USER_QUERY = gql`
  query CurrentUser{
    me{
      id
      name
    }
  }
`

interface CurrentUserResponse{
  me: {
    id: number
    name: string
  }
}

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($user: ID!, $password: String!){
    changeUserPassword(id: $user, password: $password)
  }
`

export const CurrentUser: React.FC<{path: string}> = () => {
  const {loading, error, data} = useQuery<CurrentUserResponse>(CURRENT_USER_QUERY)
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION)
  const {formBind, bind, onSubmit, validate, valid, clear} = useForm({
    password: '',
    confirmPassword: ''
  })

  onSubmit(async ({password}) => {
    await changePassword({variables: {user: me.id, password}})
    clear()
  })

  validate('confirmPassword', (value, {password}) => {
    return value === password
  })

  if(loading){
    return <PageLoader title="Current User" message="Loading current user" />
  }

  if(error){
    throw error
  }

  const {me} = data!

  return <Page>
    <Heading text={me.name} actions={[]} />
    <Box cols={2}>
      You?
    </Box>
    <Box cols={2}>
      <h3>Change Password</h3>
      <Form {...formBind()}>
        <Label>New Password</Label>
        <Input {...bind('password')} />
        <Label>Confirm New Password</Label>
        <Input {...bind('confirmPassword')} />
        <Submit label="Change Password" disabled={!valid()} />
      </Form>
    </Box>
  </Page>
}