import React from 'react'
import {Router} from '@reach/router'
import {Notifications} from '@caesium/hook-notifications'

import {Apollo} from './components/utility/apollo'
import {RequireLogin} from './components/utility/require-login'
import {RouteGroup} from './components/utility/route'

import {Layout} from './components/layout/layout'

import {UserContext} from './providers/user'

import {Dashboard} from './pages/dashboard'

import {ViewQuote} from './pages/quote/view'

import {NewProductType} from './pages/product_types/new'
import {ProductTypes} from './pages/product_types/index'
import {ProductType} from './pages/product_types/view'
import {AddProduct} from './pages/product_types/add'
import {ViewProduct} from './pages/product_types/product'

import {ViewPurchaseOrder} from './pages/purchase_orders/view'

import {CurrentUser} from './pages/users/me'
import {ListUsers} from './pages/users/list'

import {IncomingStock} from './pages/stock_control/incoming'
import {StockLookup} from './pages/stock_control/lookup'
import {OutgoingStock} from './pages/stock_control/outgoing'

import {XeroAccount} from './pages/xero_account'

import {CustomersList} from './pages/contacts/customers'
import {Customer} from './pages/contacts/customer'
import {SuppliersList} from './pages/contacts/suppliers'
import {Supplier} from './pages/contacts/supplier'

export const App: React.FC = () => {
  return <UserContext>
    <Apollo>
      <RequireLogin>
        <Notifications>
          <Layout>
            <Router>
              <Dashboard path="/" />
              <RouteGroup path="/customers">
                <CustomersList path="/" />
                <Customer path="/:customer" />
              </RouteGroup>
              <RouteGroup path="/suppliers">
                <SuppliersList path="/" />
                <Supplier path="/:supplier" />
              </RouteGroup>
              <RouteGroup path="product_types">
                <ProductTypes path="/" />
                <NewProductType path="/new" />
                <ProductType path="/:type" />
                <AddProduct path="/:type/add" />
                <ViewProduct path="/:type/:product" />
              </RouteGroup>
              <RouteGroup path="/purchase_orders">
                <ViewPurchaseOrder path="/:purchase" />
              </RouteGroup>
              <RouteGroup path="stock_control">
                <IncomingStock path="/incoming" />
                <StockLookup path="/lookup" />
                <OutgoingStock path="/outgoing" />
              </RouteGroup>
              <RouteGroup path="users">
                <ListUsers path="/" />
                <CurrentUser path="me" />
              </RouteGroup>
              <RouteGroup path="quote">
                <ViewQuote path="/:quote" />
              </RouteGroup>
              <XeroAccount path="/xero_account" />
            </Router>
          </Layout>
        </Notifications>
      </RequireLogin>
    </Apollo>
  </UserContext>
}
