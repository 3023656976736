import React from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useForm} from 'react-hook-use-form'
import {waitFor} from '@arcath/utils'
import {navigate} from '@reach/router'

import {Loader} from '../../components/elements/loader'

import {Form, Input, Label, Select, Submit, Checkbox} from '../../components/blocks/form'

import {Page, Heading, Box} from '../../components/layout/page'

const NEW_PRODUCT_TYPE_FORM_QUERY = gql`
  query NewProductTypeForm{
    allNominalCode{
      nominalCodes{
        id
        name
      }
    }
  }
`

const ADD_PRODUCT_TYPE_MUTATION = gql`
  mutation AddNewProductType($name: String!, $serialed: Boolean!, $purchaseCode: ID!, $saleCode: ID!){
    addProductType(name: $name, serialNumbered: $serialed, purchaseNominalCode: $purchaseCode, saleNominalCode: $saleCode){
      id
    }
  }
`

interface NewProductTypeFormResponse{
  allNominalCode: {
    nominalCodes: {
      id: number
      name: string
    }[]
  }
}

export const NewProductType: React.FC<{path: string}> = () => {
  const {loading, error, data} = useQuery<NewProductTypeFormResponse>(NEW_PRODUCT_TYPE_FORM_QUERY)
  const {formBind, bind, onSubmit} = useForm({
    name: '',
    serialed: false,
    purchaseCode: 0,
    saleCode: 0
  })
  const [addProductType] = useMutation(ADD_PRODUCT_TYPE_MUTATION)

  onSubmit(async ({name, purchaseCode, saleCode, serialed}) => {
    const [result, error] = await waitFor(addProductType({
      variables: {
        name, purchaseCode, saleCode, serialed
      }
    }))

    if(error){
      throw error
    }

    navigate(`/product_types/${result!.data.addProductType.id}`)
  })

  const content = () => {
    if(loading){
      return <Loader message={`Loading new Product Type Form`} />
    }

    if(error){
      throw error
    }

    const nominalCodes = data!.allNominalCode.nominalCodes

    return <>
      <Box cols={3}>
        <Form {...formBind()}>
          <Label>Name</Label>
          <Input {...bind('name')} />
          <Label>Requires Serial Number</Label>
          <Checkbox {...bind('serialed')} />
          <Label>Purchase Nomial Code</Label>
          <Select {...bind('purchaseCode')}>
            <option value={0}>Select</option>
            {nominalCodes.map((code) => {
              return <option key={code.id} value={code.id}>{code.name}</option>
            })}
          </Select>
          <Label>Sale Nomial Code</Label>
          <Select {...bind('saleCode')}>
            <option value={0}>Select</option>
            {nominalCodes.map((code) => {
              return <option key={code.id} value={code.id}>{code.name}</option>
            })}
          </Select>
          <Submit label="Create Product Type" />
        </Form>
      </Box>
      <Box cols={1}>
        <p>Create a new product type. Once created a type can not be deleted for data integrity reasons. It can however be edited.</p>
      </Box>
    </>
  }

  return <Page>
    <Heading text={`New Product Type`} actions={[
      {to: '/product_types', title: 'Back', color: 'yellow-300'}
    ]} />
    {content()}
  </Page>
}