import React from 'react'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'
import {Link} from '@reach/router'

import {PageLoader} from '../../components/elements/loader'

import {Page, Heading, Box, StatBox} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'

const VIEW_PRODUCT_QUERY = gql`
  query ViewProductQuery($product: ID!, $type: ID!){
    productType(id: $type){
      id
      name
    }

    product(id: $product){
      id
      name
      xeroItemCode
      xeroUUID
      items{
        count
      }
      receivable: items(scope: RECEIVABLE){
        count
      }
      inStock: items(scope: INSTOCK){
        count
        items{
          id
          stockControlNumber
          purchaseOrder{
            id
            orderNumber
          }
        }
      }
      delivered: items(scope: DELIVERED){
        count
      }
    }
  }
`

interface ViewProductResponse{
  productType: {
    id: number
    name: string
  }
  product: {
    id: number
    name: string
    xeroItemCode: string
    xeroUUID: string
    items: {
      count: number
    }
    receivable: {
      count: number
    }
    inStock: {
      count: number
      items: {
        id: number
        stockControlNumber: string
        purchaseOrder?: {
          id: number
          orderNumber: string
        }
      }[]
    }
    delivered: {
      count: number
    }
  }
}

export const ViewProduct: React.FC<{path: string, type?: string, product?: string}> = ({type, product: productId}) => {
  const {loading, error, data} = useQuery<ViewProductResponse>(VIEW_PRODUCT_QUERY, {
    variables: {type, product: productId}
  })

  if(loading){
    return <PageLoader title={"Loading..."} message={"Loading Product"} />
  }

  if(error){
    throw error
  }

  const {productType, product} = data!

  return <Page>
    <Heading text={product.name} actions={[
      {to: `/product_types/${type}`, title: `Back to ${productType.name}`, color: 'yellow-400'},
      {to: `/product_types/${type}/${productId}/edit`, title: 'Edit Product Type', color: 'blue-400'}
    ]} />
    <Box cols={1}>
      Xero: <a href={`https://go.xero.com/Accounts/Inventory/${product.xeroUUID}`} target="_BLANK" rel="noopener noreferrer">{product.xeroItemCode}</a>
    </Box>
    <StatBox stat={product.receivable.count} title="Incoming" cols={1} />
    <StatBox stat={product.inStock.count} title="In Stock" cols={1} />
    <StatBox stat={product.delivered.count} title="With Customers" cols={1} />
    <StatBox stat={product.items.count} title="All Items" cols={1} />
    <Box cols={3} rows={2}>
      <h3>In Stock</h3>
      <Table>
        <thead>
          <tr>
            <th>SCN</th>
            <th>PO</th>
          </tr>
        </thead>
        <tbody>
          {product.inStock.items.map((item) => {
            return <tr key={item.id}>
              <Cell>{item.stockControlNumber}</Cell>
              <Cell>
                {!item.purchaseOrder ? 'Manually Created' : <Link to={`/purchase_orders/${item.purchaseOrder.id}`}>{item.purchaseOrder.orderNumber}</Link>}
              </Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
  </Page>
}