import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {Link} from '@reach/router'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'

import {PageLoader} from '../../components/elements/loader'


const CUSTOMERS_QUERY = gql`
  query CustomersQuery{
    allContact(type: CUSTOMER){
      count
      contacts{
        id
        name
        quotes{
          count
        }
      }
    }
  }
`

interface CustomerResult{
  allContact: {
    count: Number
    contacts: {
      id: number
      name: string
      quotes: {
        count: number
      }
    }[]
  }
}

export const CustomersList: React.FC<{path: string}> = () => {
  const {loading, error, data} = useQuery<CustomerResult>(CUSTOMERS_QUERY)

  if(loading){
    return <PageLoader message="Loading Customers" title="Customers" />
  }

  if(error){
    throw error
  }

  const customers = data!.allContact.contacts

  return <Page>
    <Heading text={`Customers (${data!.allContact.count})`} actions={[]} />
    <Box cols={4}>
      <Table>
        <thead>
          <tr>
            <th>Customer</th>
            <th>Quotes</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => {
            return <tr key={customer.id}>
              <Cell><Link to={`/customers/${customer.id}`}>{customer.name}</Link></Cell>
              <Cell>{customer.quotes.count}</Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
  </Page>
}