import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {Link} from '@reach/router'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'

import {PageLoader} from '../../components/elements/loader'

const CUSTOMER_QUERY = gql`
  query Customer($customer: ID!){
    contact(id: $customer){
      id
      name
      quotes{
        count
        quotes{
          id
          quoteNumber
        }
      }
      items{
        count
        items{
          id
          stockControlNumber
          product{
            id
            name
          }
        }   
      }
    }
  }
`

interface CustomerResponse{
  contact: {
    id: number
    name: string
    quotes: {
      count: number
      quotes: {
        id: number
        quoteNumber: string
      }[]
    }
    items: {
      count: number
      items: {
        id: number
        stockControlNumber: string
        product: {
          id: number
          name: string
        }
      }[]
    }
  }
}

export const Customer: React.FC<{path: string, customer?: string}> = ({customer: customerId}) => {
  const {loading, error, data} = useQuery<CustomerResponse>(CUSTOMER_QUERY, {variables: {
    customer: customerId
  }})

  if(loading){
    return <PageLoader title="Loading Customer" message="Loading customer" />
  }

  if(error){
    throw error
  }

  const customer = data!.contact

  return <Page>
    <Heading text={customer.name} actions={[]} />
    <Box cols={1}>
      <h2>Quotes ({customer.quotes.count})</h2>
      <Table>
        <thead>
          <tr>
            <th>Quote</th>
          </tr>
        </thead>
        <tbody>
          {customer.quotes.quotes.map((quote) => {
            return <tr key={quote.id}>
              <Cell><Link to={`/quote/${quote.id}`}>{quote.quoteNumber}</Link></Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
    <Box cols={3} rows={2}>
      <h2>Items ({customer.items.count})</h2>
      <Table>
        <thead>
          <tr>
            <th>SCN</th>
            <th>Product</th>
          </tr>
        </thead>
        <tbody>
          {customer.items.items.map((item) => {
            return <tr key={item.id}>
              <Cell>{item.stockControlNumber}</Cell>
              <Cell>{item.product.name}</Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
  </Page>
}