import React from 'react'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'
import {Link} from '@reach/router'

import {PageLoader} from '../../components/elements/loader'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'

const PRODUCT_TYPES_QUERY = gql`
  query ProductTypes{
    allProductTypes{
      count
      productTypes{
        id
        name
        products{
          id
        }
      }
    }
  }
`

interface ProductTypesResponse{
  allProductTypes: {
    count: number
    productTypes: {
      id: number
      name: string
      products: {
        id: number
      }[]
    }[]
  }
}

export const ProductTypes: React.FC<{path: string}> = () => {
  const {loading, error, data} = useQuery<ProductTypesResponse>(PRODUCT_TYPES_QUERY)

  if(loading){
    return <PageLoader title="Product Types" message="Loading Product Types" />
  }

  if(error){
    throw error
  }

  const {productTypes, count} = data!.allProductTypes

  return <Page>
    <Heading text={`Products (${count})`} actions={[
      {to: '/product_types/new', title: 'New Product Type', color: 'green-300'}
    ]}/>
    <Box cols={4}>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Products</th>
          </tr>
        </thead>
        <tbody>
          {productTypes.map((type) => {
            return <tr key={type.id}>
              <Cell><Link to={`/product_types/${type.id}`}>{type.name}</Link></Cell>
              <Cell>{type.products.length}</Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
  </Page>
}