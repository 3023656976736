import React from 'react'

export const Table: React.FC = ({children}) => {
  return <table className={`border-collapse table-auto w-full`}>
    {children}
  </table>
}

export const Cell: React.FC<{colSpan?: number, rowSpan?: number, header?: boolean}> = ({children, colSpan, rowSpan, header}) => {
  return <td className={`border px-4 py-2 ${header ? 'align-top bg-gray-300 text-black' : ''}`} colSpan={colSpan} rowSpan={rowSpan}>{children}</td>
}