import React from 'react'
import gql from 'graphql-tag'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {useForm} from 'react-hook-use-form'

import {Page, Box, Heading} from '../../components/layout/page'
import {PageLoader} from '../../components/elements/loader'

import {Table, Cell} from '../../components/layout/table'
import {Button} from '../../components/blocks/button'
import {Form, Input, Label, Submit} from '../../components/blocks/form'

const USERS_QUERY = gql`
  query AllUsers{
    me{
      id
      manager
    }

    allUsers{
      count
      users{
        id
        name
        manager
        enabled
      }
    }
  }
`

interface UsersResponse{
  me: {
    id: number
    manager: boolean
  }

  allUsers: {
    count: number
    users: {
      id: number
      name: string
      manager: boolean
      enabled: boolean
    }[]
  }
}

const TOGGLE_USER_ENABLED_MUTATION = gql`
  mutation ToggleUserEnabled($user: ID!){
    toggleUserEnabled(id: $user)
  }
`

const TOGGLE_USER_MANAGER_MUTATION = gql`
  mutation ToggleUserManager($user: ID!){
    toggleUserManager(id: $user)
  }
`

const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($user: ID!, $password: String!){
    changeUserPassword(id: $user, password: $password)
  }
`

export const ListUsers: React.FC<{path: string}> = () => {
  const {loading, error, data, refetch} = useQuery<UsersResponse>(USERS_QUERY)
  const [toggleUserEnabled] = useMutation(TOGGLE_USER_ENABLED_MUTATION)
  const [toggleUserManager] = useMutation(TOGGLE_USER_MANAGER_MUTATION)
  const [resetUserPassword] = useMutation(RESET_USER_PASSWORD)

  if(loading){
    return <PageLoader title="Users" message="Loading users" />
  }

  if(error){
    console.dir(error)
    throw error
  }

  const {me} = data!
  const {count, users} = data!.allUsers

  return <Page>
    <Heading text={`Users (${count})`} actions={[
      {title: 'New User', color: 'green-300', to: '/users/new'}
    ]} />
    <Box cols={2}>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            {me.manager ? <th>Actions</th> : ''}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return <tr key={user.id}>
              <Cell>{user.name}</Cell>
              {me.manager ? <Cell>
                <Button color={user.enabled ? 'red-300' : 'green-300'} onClick={async () => {
                  await toggleUserEnabled({variables: {user: user.id}})
                  refetch()
                }}>{user.enabled ? 'Disable' : 'Enable'}</Button>
                <Button color={user.manager ? 'red-300' : 'green-300'} onClick={async () => {
                  await toggleUserManager({variables: {user: user.id}})
                  refetch()
                }}>{user.manager ? 'Demote' : 'Promote'}</Button>
                <Button color={`yellow-300`} onClick={async () => {
                  await resetUserPassword({variables: {
                    user: user.id,
                    password: 'Pu6wash0'
                  }})
                }}>Reset Password</Button>
              </Cell> : ''}
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
    <Box cols={2}>
      <p>Disable a user so that they can't use the API.</p>
      <p>Promote/Demote a user to/from a manager.</p>
      <p>Reset a users password to <i>Pu6wash0</i></p>
    </Box>
    {me.manager ? <NewUser refetch={refetch} /> : ''}
  </Page>
}

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($name: String!, $email: String!, $password: String!){
    addUser(name: $name, email: $email, password: $password){
      id
    }
  }
`

const NewUser: React.FC<{refetch: () => void}> = ({refetch}) => {
  const {formBind, bind, onSubmit, clear} = useForm({
    email: '',
    name: '',
    password: ''
  })
  const [createUser] = useMutation(CREATE_USER_MUTATION)

  onSubmit(async ({name, email, password}) => {
    await createUser({
      variables: {
        email, name, password
      }
    })
    clear()
    refetch()
  })

  return <Box cols={4}>
    <h3>New User</h3>
    <Form {...formBind()}>
      <Label>Name</Label>
      <Input {...bind('name')} />
      <Label>Email</Label>
      <Input {...bind('email')} />
      <Label>Password</Label>
      <Input {...bind('password')} type="password" />
      <Submit label="Create User" />
    </Form>
  </Box>
}