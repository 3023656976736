import {useNotify as notify, UseNotifyFunction, useNotifications as notifications, UseNotificationsFunction} from '@caesium/hook-notifications'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

interface Action{
  label: string
  onClick: () => void
  icon?: IconDefinition
}

interface NewNotification{
  title: string
  message: string
  icon?: IconDefinition
  actions?: Action[]
  color?: string
}

export interface Notification extends NewNotification{
  uuid: string
}

export const useNotify: UseNotifyFunction<NewNotification> = () => {
  return notify()
}

export const useNotifications: UseNotificationsFunction<Notification> = () => {
  return (notifications() as any)
}