import React, {useState} from 'react'
import {useQuery, useApolloClient, useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {asyncForEach} from '@arcath/utils'

import {PageLoader} from '../../components/elements/loader'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'
import {Input} from '../../components/blocks/form'
import {Button} from '../../components/blocks/button'
import {Modal} from '../../components/elements/modal'

const QUOTE_QUERY = gql`
  query Quote($quote: ID!){
    quote(id: $quote){
      id
      quoteNumber
      xeroUUID
      customer{
        id
        name
      }
      lines{
        id
        quantity
        product{
          id
          name
        }
        items{
          id
          item{
            id
            stockControlNumber
            delivered
            description
            purchasePrice
          }
        }
      }
    }
  }
`

interface QuoteResponse{
  quote: {
    id: number
    quoteNumber: string
    xeroUUID: string
    customer: {
      name: string
    }
    lines: {
      id: number
      quantity: number
      product: {
        id: number
        name: string
      }
      items: {
        id: number
        item: {
          id: number
          stockControlNumber: string
          delivered: boolean
          description: string
          purchasePrice: number
        }
      }[]
    }[]
  }
}

const SCN_COMPLETE_QUERY = gql`
  query QuoteBarcodeCompleteQuery($stockControlNumber: String!){
    item(stockControlNumber: $stockControlNumber, scope: INSTOCK){
      id
      product{
        id
      }
    }
  }
`

const ADD_ITEM_TO_LINE_MUTATION = gql`
  mutation AddItemToLine($line: ID, $item: ID!, $quote: ID){
    attachItem(id: $item, line: $line, quote: $quote)
  }
`

const REMOVE_ITEM_FROM_LINE_MUTATION = gql`
  mutation RemoveItemFromLine($line: ID!, $item: ID!){
    removeItem(id: $item, line: $line)
  }
`

const DELIVER_ITEM_MUTATION = gql`
  mutation DeliverItemMutation($item: ID!){
    deliverItem(id: $item){
      id
    }
  }
`

const REMOVE_LINE_MUTATION = gql`
  mutation RemoveLineMutation($line: ID!){
    removeLine(line: $line)
  }
`

const FINISH_LINE_MUTATION = gql`
  mutation FinishLineMutation($line: ID!){
    finishLine(line: $line)
  }
`

export const ViewQuote: React.FC<{path: string, quote?: string}> = ({quote: quoteId}) => {
  const {loading, error, data, refetch} = useQuery<QuoteResponse>(QUOTE_QUERY, {variables: {
    quote: quoteId
  }})
  const {query, mutate} = useApolloClient()
  const [product, setProduct] = useState(0)
  const [fillLine, setFillLine] = useState(0)

  if(loading){
    return <PageLoader title="Quote" message="Loading Quote" />
  }

  if(error){
    throw error
  }


  const {quote} = data!

  const products = quote.lines.map((line) => {
    return line.product.id
  })

  const lineByProduct: {[product: string]: number} = {}

  quote.lines.forEach((line) => {
    lineByProduct[line.product.id] = line.id
  })

  return <Page>
    {product !== 0 ? <Modal onClose={() => {
      setProduct(0)
      setFillLine(0)
    }} title="Item Filler"><ItemFiller product={product} line={fillLine} reload={refetch} /></Modal> : ''}
    <Heading text={`Quote ${quote.quoteNumber}`} actions={[]} />
    <Box cols={1}>
      <h3>{quote.customer.name}</h3>
      <p>
        <a href={`https://go.xero.com/Accounts/Receivable/Quotes/View/${quote.xeroUUID}`}>View on Xero</a>
      </p>
    </Box>
    <Box cols={3}>
      <Table>
        <tbody>
          {quote.lines.map((line) => {
            return <>
              <tr key={line.id}>
                <Cell header rowSpan={line.items.length + 1}>
                  <span onClick={() => {
                    setProduct(line.product.id)
                    setFillLine(line.id)
                  }}>{line.product.name}</span> ({
                    line.quantity > line.items.length ?
                    `${line.items.length}/${line.quantity}` :
                    line.quantity
                  })<br />
                  {line.items.length === 0 ? <Button color={`red-300`} onClick={async () => {
                    await mutate({
                      mutation: REMOVE_LINE_MUTATION,
                      variables: {
                        line: line.id
                      }
                    })

                    refetch()
                  }}>Remove Line</Button> : ''}
                  {line.quantity > line.items.length ? <Button color={`yellow-300`} onClick={async () => {
                    await mutate({
                      mutation: FINISH_LINE_MUTATION,
                      variables: {
                        line: line.id
                      }
                    })

                    refetch()
                  }}>Finish Line</Button> : ''}
                </Cell>
                <Cell>Stock Control Numbers</Cell>
                <Cell>Item Description</Cell>
                <Cell>Purchase Price (ex VAT)</Cell>
                <Cell>
                  {
                    !(line.items.reduce<boolean>((c, item) => {
                      return c && item.item.delivered
                    }, true)) ? <Button color={`yellow-300`} onClick={async () => {
                      await asyncForEach(line.items, async ({id, item}) => {
                        if(!item.delivered){
                          console.log(`deliver ${item.id}`)
                          await mutate({
                            mutation: DELIVER_ITEM_MUTATION,
                            variables: {
                              item: item.id
                            }
                          })
                        }

                      })

                      refetch()
                    }}>Deliver All</Button> : ''
                  }
                </Cell>
              </tr>
              {line.items.map(({id, item}) => {
                return <tr key={item.id}>
                  <Cell>{item.stockControlNumber}</Cell>
                  <Cell>{item.description}</Cell>
                  <Cell>&pound;{item.purchasePrice}</Cell>
                  <Cell>
                    <Button color={"red-300"} onClick={async () => {
                      await mutate({
                        mutation: REMOVE_ITEM_FROM_LINE_MUTATION,
                        variables: {
                          item: item.id,
                          line: id
                        }
                      })

                      refetch()
                    }}>
                      Remove
                    </Button>
                    {item.delivered ? '' : <Button color={`yellow-300`} onClick={async () => {
                      await mutate({
                        mutation: DELIVER_ITEM_MUTATION,
                        variables: {
                          item: item.id
                        }
                      })

                      refetch()
                    }}>
                      Deliver
                    </Button>}
                  </Cell>
                </tr>
              })}
            </>
          })}
          <tr>
            <Cell header>Add to Quote</Cell>
            <Cell colSpan={2}>
              <Input autoFocus onChange={async (e: any) => {
                if(e.target.value === ''){
                  return
                }

                const result = await query({
                  query: SCN_COMPLETE_QUERY,
                  variables: {
                    stockControlNumber: e.target.value
                  },
                  errorPolicy: 'ignore'
                })

                if(result.data.item){
                  if(products.includes(result.data.item.product.id)){
                    const lineId = lineByProduct[result.data.item.product.id]

                    await mutate({
                      mutation: ADD_ITEM_TO_LINE_MUTATION,
                      variables: {
                        line: lineId,
                        item: result.data.item.id
                      }
                    })

                    await refetch()
                  }else{
                    await mutate({
                      mutation: ADD_ITEM_TO_LINE_MUTATION,
                      variables: {
                        quote: quote.id,
                        item: result.data.item.id
                      }
                    })

                    await refetch()
                  }
                }
              }} />
            </Cell>
            <Cell />
            <Cell />
          </tr>
        </tbody>
      </Table>
    </Box>
  </Page>
}

const ITEM_FINDER_QUERY = gql`
  query ItemFillerQuery($product: ID!){
    product(id: $product){
      id
      items(scope: INSTOCK_UNASSIGNED){
        count
        items{
          id
          description
          stockControlNumber
          purchaseOrder{
            id
            orderNumber
          }
        }
      }
    }
  }
`

interface ItemFinderResult{
  product: {
    id: number
    items: {
      count: number
      items: {
        id: number
        description: string
        stockControlNumber: string
        purchaseOrder?: {
          id: number
          orderNumber: string
        }
      }[]
    }
  }
}

const ItemFiller: React.FC<{product: number, line: number, reload: () => void}> = ({product, line, reload}) => {
  const {loading, error, data, refetch} = useQuery<ItemFinderResult>(ITEM_FINDER_QUERY, {variables: {
    product
  }})
  const [attachItem] = useMutation(ADD_ITEM_TO_LINE_MUTATION)

  if(loading){
    return <>Loading...</>
  }

  if(error){
    throw error
  }

  const items = data!.product.items.items

  const purchaseOrders: {[purchaseOrder: string]: ItemFinderResult["product"]["items"]["items"]} = {}

  items.forEach((item) => {
    if(!purchaseOrders[item.purchaseOrder ? item.purchaseOrder.orderNumber : 'Manual']){
      purchaseOrders[item.purchaseOrder ? item.purchaseOrder.orderNumber : 'Manual'] = []
    }

    purchaseOrders[item.purchaseOrder ? item.purchaseOrder.orderNumber : 'Manual'].push(item)
  })

  if(items.length === 0){
    return <>
      <p>No Items in stock.</p>
    </>
  }

  return <>
    <Table>
      <tbody>
        {Object.keys(purchaseOrders).map((poNumber) => {
          const poItems = purchaseOrders[poNumber]

          return <>
            <tr key={poNumber}>
        <Cell header={true} rowSpan={poItems.length + 1}>{poNumber} ({poItems.length})</Cell>
              <Cell header={true}></Cell>
              <Cell header={true}></Cell>
              <Cell header={true}>
                <Button color="green-500" onClick={async () => {
                  await asyncForEach(poItems, async (item) => {
                    await attachItem({variables: {
                      line,
                      item: item.id
                    }})
                  })

                  refetch()
                  reload()
                }}>Add All</Button>
              </Cell>
            </tr>
            {poItems.map((item) => {
              return <tr key={item.id}>
                <Cell>{item.description}</Cell>
                <Cell>{item.stockControlNumber}</Cell>
                <Cell>
                  <Button color="green-300" onClick={async () => {
                      await attachItem({variables: {
                        line,
                        item: item.id
                      }})

                      refetch()
                      reload()
                    }}>Add To Line</Button>
                </Cell>
              </tr>
            })}
          </>
        })}
      </tbody>
    </Table>
  </>
}
