import React from 'react'
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {Link, navigate} from '@reach/router'
import {useForm} from 'react-hook-use-form'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'
import {Button} from '../../components/blocks/button'
import {Input, Submit} from '../../components/blocks/form'

import {PageLoader} from '../../components/elements/loader'

import {useNotify} from '../../hooks/notifications'

const OUTGOING_STOCK_QUERY = gql`
  query OutgoingStockQuery{
    importableQuotes{
      number
      uuid
      lines
      contact
    }

    inProgress: allQuote(scope: INPROGRESS){
      count
      quotes{
        id
        quoteNumber
        lines{
          id
        }
        customer{
          id
          name
        }
      }
    }

    deliverable: allQuote(scope: DELIVERABLE){
      count
      quotes{
        id
        quoteNumber
        lines{
          id
        }
        customer{
          id
          name
        }
      }
    }
  }
`

interface OutgoingStockResponse{
  importableQuotes: {
    number: string
    uuid: string
    contact: string
    lines: number
  }[]
  inProgress: QuoteList
  deliverable: QuoteList
}

interface QuoteList{
  count: number
  quotes: {
    id: number
    quoteNumber: string
    lines: {
      id: number
    }[]
    customer: {
      id: number
      name: string
    }
  }[]
}

const IMPORT_QUOTE_MUTATION = gql`
  mutation ImportQuote($quote: String!){
    importQuote(uuid: $quote){
      id
    }
  }
`

const FIND_UUID_QUERY = gql`
  query FindQuoteUUID($number: String!){
    quoteNumberAsUUID(number: $number)
  }
` 

export const OutgoingStock: React.FC<{path: string}> = () => {
  const {loading, error, data, refetch} = useQuery<OutgoingStockResponse>(OUTGOING_STOCK_QUERY)
  const [importQuote] = useMutation(IMPORT_QUOTE_MUTATION)

  if(loading){
    return <PageLoader title="Outgoing Stock" message="Loading Quotes" />
  }

  if(error){
    throw error
  }

  const {inProgress, deliverable, importableQuotes} = data!

  return <Page>
    <Heading text="Outgoing Stock" actions={[
      {title: 'Reload', color: 'yellow-300', onClick: () => {refetch()}}
    ]} />
    <Box cols={1} rows={2}>
      <h3>About</h3>
      <p>Pipeline contains all none imported quotess that have a status of <i>Accepted</i> on Xero.</p>
      <p>Manual allows you to manually import a Quote from Xero using its Quote Number.</p>
    </Box>
    <Box cols={3}>
      <h3>Pipeline</h3>
      <Table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Customer</th>
            <th>Lines</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {importableQuotes.map((quote) => {
            return <tr key={quote.number}>
              <Cell>
                <a href={`https://go.xero.com/Accounts/Receivable/Quotes/View/${quote.uuid}`} target="_BLANK" rel="noopener noreferrer">{quote.number}</a>
              </Cell>
              <Cell>
                {quote.contact}
              </Cell>
              <Cell>
                {quote.lines}
              </Cell>
              <Cell>
                <Button color={`yellow-300`} onClick={async () => {
                  await importQuote({
                    variables: {
                      quote: quote.uuid
                    }
                  })

                  refetch()
                }}>
                  Import
                </Button>
              </Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
    <Box cols={3}>
      <ManualImport refetch={refetch} />
    </Box>
    <QuoteList quotes={inProgress} title="In Progress" />
    <QuoteList quotes={deliverable} title="Deliverable" />
  </Page>
}

const QuoteList: React.FC<{quotes: QuoteList, title: string}> = ({quotes, title}) => {
  return <Box cols={4}>
    <h3>{title} ({quotes.count})</h3>
    <Table>
      <thead>
        <tr>
          <th>Number</th>
          <th>Lines</th>
        </tr>
      </thead>
      <tbody>
        {quotes.quotes.map((quote) => {
          return <tr key={quote.id}>
            <Cell header><Link to={`/quote/${quote.id}`}>{quote.quoteNumber}</Link> ({quote.customer.name})</Cell>
            <Cell>{quote.lines.length}</Cell>
          </tr>
        })}
      </tbody>
    </Table>
  </Box>
}

const ManualImport: React.FC<{refetch: () => void}> = ({refetch}) => {
  const {formBind, bind, onSubmit} = useForm({
    number: ''
  })
  const {query, mutate} = useApolloClient()
  const {notify} = useNotify()

  onSubmit(async ({number}) => {
    let result: any

    try{
      result = await query({
        query: FIND_UUID_QUERY,
        variables: {number}
      })
    }catch(e){
      console.log('Error')
      return
    }

    const addResult = await mutate({
      mutation: IMPORT_QUOTE_MUTATION,
      variables: {quote: result.data.quoteNumberAsUUID}
    })

    refetch()
    notify({
      title: 'Quote Imported',
      message: `Quote ${number} has been manually imported from Xero.`,
      actions: [
        {
          label: 'View',
          onClick: () => {
            navigate(`/quote/${addResult.data.importQuote.id}`)
          }
        }
      ]
    })
  })

  return <>
    <h3>Manual Import</h3>
    <form {...formBind()}>
      <Page>
        <div className="col-span-3">
          <Input placeholder={"QU-XXXX"} {...bind('number')} />
        </div>
        <div className="col-span-1">
          <Submit label="Import" />
        </div>
      </Page>
    </form>
  </>
}