import React from 'react'
import {useForm} from 'react-hook-use-form'
import gql from 'graphql-tag'
import {useMutation} from '@apollo/react-hooks'
import {waitFor} from '@arcath/utils'
import {navigate} from '@reach/router'

import {Form, Input, Label, Submit} from '../../components/blocks/form'

import {Page, Heading, Box} from '../../components/layout/page'

const ADD_PRODUCT_MUTATION = gql`
  mutation AddProductMutation($name: String!, $type: ID!){
    addProduct(name: $name, productType: $type){
      id
    }
  }
`

export const AddProduct: React.FC<{path: string, type?: string}> = ({type}) => {
  const {formBind, bind, onSubmit} = useForm({
    name: ''
  })
  const [addProuct] = useMutation(ADD_PRODUCT_MUTATION)

  onSubmit(async ({name}) => {
    const [addProductResult, error] = await waitFor(addProuct({
      variables: {name, type}
    }))

    if(error){
      throw error
    }

    navigate(`/product_types/${type}/${addProductResult!.data.addProduct.id}`)
  })

  return <Page>
    <Heading text="Add Product" actions={[

    ]} />
    <Box cols={3}>
      <Form {...formBind()}>
        <Label>Name</Label>
        <Input {...bind('name')} />
        <Submit label="Create Product" />
      </Form>
    </Box>
    <Box cols={1}>
      <p>Create a new product. Once created a product can not be deleted for data integrity reasons. It can however be edited.</p>
    </Box>
  </Page>
}