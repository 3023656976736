import React from 'react'

import {useUser} from '../../providers/user'

import {Login} from '../../pages/login'

export const RequireLogin: React.FC = ({children}) => {
  const user = useUser()

  if(!user.token){
    return <Login />
  }else{
    return <>{children}</>
  }
}