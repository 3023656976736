import React, {useState} from 'react'
import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useForm} from 'react-hook-use-form'
import {waitFor} from '@arcath/utils'

import {Form, Label, Input, Submit} from '../components/blocks/form'

import {useUserActions} from '../providers/user'

const AUTHENTICATE_MUTATION = gql`
  mutation AuthenticateMutation($email: String!, $password: String!){
    authenticate(email: $email, password: $password)
  }
`

export const Login: React.FC = () => {
  const {login} = useUserActions()
  const [errorMessage, setErrorMessage] = useState('')
  const [authenticate] = useMutation(AUTHENTICATE_MUTATION)
  const {formBind, bind, onSubmit} = useForm({
    email: '',
    password: ''
  })

  onSubmit(async ({email, password}) => {
    const [authenticateResult, error] = await waitFor(authenticate({
      variables: {
        email, password
      }
    }))

    if(error){
      setErrorMessage('Login Error')
      
      return
    }

    const token = authenticateResult!.data.authenticate

    login({token})
  })

  return <>
    <div className={`w-96 m-auto mt-32`}>
      <div className={`text-center w-full text-4xl mb-4`}>
        <img src={`/logo.png`} alt="Warehouse Logo" className={`w-8 m-auto w-32`} />
        Warehouse
      </div>
      <div className={`shadow-xl rounded-lg p-2 border border-gray-100`}>
        <p className={`text-center text-red-500 mb-2`}>{errorMessage}</p>
        <Form {...formBind()}>
          <Label>Email</Label>
          <Input {...bind('email')} />
          <Label>Password</Label>
          <Input type="password" {...bind('password')} />
          <Submit label="Login" />
        </Form>
      </div>
    </div>
  </>
}