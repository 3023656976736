import React from 'react'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'
import {Link} from '@reach/router'

import {PageLoader} from '../../components/elements/loader'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'

const VIEW_PRODUCT_TYPE_QUERY = gql`
  query ViewProductTypeQuery($type: ID!){
    productType(id: $type){
      id
      name
      products{
        id
        name
      }
    }
  }
`

interface ViewProductTypeResponse{
  productType: {
    id: number
    name: string
    products: {
      id: number
      name: string
    }[]
  }
}

export const ProductType: React.FC<{path: string, type?: string}> = ({type}) => {
  const {loading, error, data} = useQuery<ViewProductTypeResponse>(VIEW_PRODUCT_TYPE_QUERY, {variables: {type}})

  if(loading){
    return <PageLoader title="Loading..." message="Loading Product Type" />
  }

  if(error){
    throw error
  }

  const productType = data!.productType

  return <Page>
    <Heading text={productType.name} actions={[
      {to: `/product_types/${productType.id}/edit`, title: 'Edit Product Type', color: 'blue-400'},
      {to: `/product_types/${productType.id}/add`, title: 'Add Product', color: 'green-300'}
    ]} />
    <Box cols={1}>
      <p className={`text-lg`}>{productType.name}</p>
    </Box>
    <Box cols={3} rows={2}>
      <p className={`text-lg`}>Products</p>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {productType.products.map((product) => {
            return <tr key={product.id}>
              <Cell><Link to={`/product_types/${productType.id}/${product.id}`}>{product.name}</Link></Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
  </Page>
}