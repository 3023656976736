import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell, faTimes} from '@fortawesome/free-solid-svg-icons'
import {useTimeoutFn} from 'react-use'

import {useNotifications, Notification} from '../../hooks/notifications'

export const Notifications: React.FC = () => {
  const {notifications, removeNotification} = useNotifications()

  return <div className="absolute top-0 right-0 mt-8 mr-8 w-96">
    {notifications.map((notification) => {
      return <NotificationComponent notification={notification} removeNotification={removeNotification} />
    })}
  </div>
}

const NotificationComponent: React.FC<{notification: Notification, removeNotification: (uuid: string) => void}> = ({notification, removeNotification}) => {
  const icon = (notification.icon ? notification.icon : faBell)
  const color = (notification.color ? notification.color : 'yellow')

  const [, cancel, reset] = useTimeoutFn(() => {
    removeNotification(notification.uuid)
  }, 5000)


  return <div
    className={`grid grid-cols-notification bg-${color}-400 border-${color}-500 border-2 rounded p-1 bg-opacity-75 relative`}
    key={notification.uuid}
    onMouseEnter={() => {
      cancel()
    }}
    onMouseLeave={() => {
      reset()
    }}
  >
    <FontAwesomeIcon icon={faTimes} className="absolute top-0 right-0 mr-2 mt-1 cursor-pointer" onClick={() => {
      removeNotification(notification.uuid)
    }} />
    <div className="row-span-3 text-center">
      <FontAwesomeIcon icon={icon} className="text-6xl" />
    </div> 
    <h2>{notification.title}</h2>
    <div dangerouslySetInnerHTML={{__html: notification.message}} />
    <div className="text-right">
      {notification.actions ? notification.actions.map((action, i) => {
        return <button key={i} onClick={action.onClick} className={`bg-${color}-500 rounded-lg pl-8 pr-8 pt-2 pb-2`}>{action.label}</button>
      }) : ''}
    </div>
  </div>
}