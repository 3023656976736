import React, {ReactHTMLElement, useState} from 'react'
import {useApolloClient} from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const Form: React.FC<ReactHTMLElement<HTMLFormElement>["props"]> = (props) => {
  return <form {...props} className={`grid grid-cols-2`} />
}

export const Input: React.FC<ReactHTMLElement<HTMLInputElement>["props"] & {error?: boolean}> = (props) => {
  return <input {...props} className={`bg-white border-gray-300 border w-full p-2 rounded mb-2 shadow-sm hover:shadow-none ${props.disabled ? 'bg-gray-300' : ''} ${props.error ? 'border-red-300' : ''} ${props.className}`} />
}

export const Label: React.FC<ReactHTMLElement<HTMLLabelElement>["props"]> = (props) => {
  return <label {...props} className={`p-2`} />
}

export const Select: React.FC<ReactHTMLElement<HTMLSelectElement>["props"]> = (props) => {
  return <select {...props} className={`bg-white border-gray-300 border w-full p-2 rounded mb-2 shadow-sm`} />
}

export const Checkbox: React.FC<{name: string, value: boolean, 'aria-label': string, onChange: (e: any) => void}> = ({value, onChange}) => {
  return <div className={`bg-${value ? 'green-300' : 'red-300'} mb-2 rounded-sm shadow-sm cursor-pointer transition`} onClick={() => onChange({target: {value: !value}})} >
    <div className={`bg-white w-16 h-8 m-1 rounded-sm float-${value ? 'right' : 'left'} transition`} />
  </div>
}

export const Submit: React.FC<{label: string, disabled?: boolean}> = ({label, disabled}) => {
  return <Input type="submit" value={label} disabled={disabled} className={`bg-${disabled ? 'gray-300' : 'green-500'} text-white col-start-2 `} />
}

export const Error: React.FC<{message: string}> = ({message}) => {
  return <p className={`text-red-400`}>{message}</p>
}

const CHECK_SCN_QUERY = gql`
  query CheckBarcode($stockControlNumber: String!){
    item(stockControlNumber: $stockControlNumber){
      id
      stockControlNumber
    }
  }
`

interface StockControlNumberInputResponse{
  stockControlNumber: string
  valid: boolean
}

export const SCNInput: React.FC<{id?: string, stockControlNumber: string, onChange: (e: StockControlNumberInputResponse) => void, placeholder?: string, disabled?: boolean}> = ({stockControlNumber, onChange, placeholder, disabled, id}) => {
  const {query} = useApolloClient()
  const [error, setError] = useState(false)
  const [working, setWorking] = useState(false)

  if(working){
    return <Input disabled={true} value={stockControlNumber} />
  }

  return <Input
    id={id}
    placeholder={placeholder}
    disabled={disabled}
    error={error}
    value={stockControlNumber}
    onChange={(e: any) => {
      onChange({
        stockControlNumber: e.target.value,
        valid: !error
      })
    }}
    onBlur={async (e: any) => {
      if(e.target.value.length > 0){
        setWorking(true)

        const result = await query<{
          item: {
            id: number
            barcode: string
          }
        }>({
          query: CHECK_SCN_QUERY,
          variables: {
            stockControlNumber: e.target.value
          },
          errorPolicy: 'ignore'
        })

        setWorking(false)

        if(result.data.item){
          onChange({
            stockControlNumber,
            valid: false
          })

          setError(true)
        }else{
          onChange({
            stockControlNumber,
            valid: true
          })

          setError(false)
        }
      }
    }}
  />
}