import React from 'react'
import gql from 'graphql-tag'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {faAddressBook, faCode} from '@fortawesome/free-solid-svg-icons'

import {useNotify} from '../hooks/notifications'

import {Page, Heading, Box} from '../components/layout/page'
import {Table, Cell} from '../components/layout/table'

const RELOAD_NOMINAL_CODES_MUTATION = gql`
  mutation ReloadNominalCodesMutation{
    reloadNominalCodes
  }
`

const RELOAD_CONTACTS_MUTATION = gql`
  mutation ReloadContactsMutation{
    reloadContact
  }
`

/** Page Component */
export const XeroAccount: React.FC<{path: string}> = () => {
  const [reloadNominalCodes] = useMutation(RELOAD_NOMINAL_CODES_MUTATION)
  const [reloadContacts] = useMutation(RELOAD_CONTACTS_MUTATION)

  const {notify} = useNotify()

  return <Page>
    <Heading text="Xero Account" actions={[]} />
    <Box cols={1}>
      <XeroLoginButton />
    </Box>
    <Box cols={1} rows={3}>
      <NominalCodes />
    </Box>
    <Box cols={2} rows={4}>
      <Contacts />
    </Box>
    <Box cols={1}>
      <button className={`bg-yellow-300 w-full p-2 rounded-sm mb-2`} onClick={async () => {
        await reloadNominalCodes()
        notify({
          title: 'Nominal Codes Imported',
          message: 'Nominal Codes imported from Xero',
          icon: faCode,
          color: 'green'
        })
      }}>Reload Nominal Codes</button>
      <button className={`bg-yellow-300 w-full p-2 rounded-sm`} onClick={async () => {
        await reloadContacts()
        notify({
          title: 'Contacts Imported',
          message: 'Contacts imported from Xero',
          icon: faAddressBook,
          color: 'green'
        })
      }}>Reload Contacts</button>
    </Box>
  </Page>
}

const GET_XERO_CONSET_URL_QUERY = gql`
  query GetXeroConsentUrl{
    xeroConsentUrl
  }
`

interface XeroConsentUrlResponse{
  xeroConsentUrl: string
}

const XeroLoginButton: React.FC = () => {
  const {loading, error, data} = useQuery<XeroConsentUrlResponse>(GET_XERO_CONSET_URL_QUERY)

  if(loading){
    return <>Loading...</>
  }

  if(error){
    throw error
  }

  return <a href={data!.xeroConsentUrl} className={`p-2 bg-green-300 w-full block rounded-sm text-gray-800`}>Link to Xero</a>
}

const GET_NOMINAL_CODES_QUERY = gql`
  query GetNominalCodes{
    allNominalCode{
      count
      nominalCodes{
        id
        name
      }
    }
  }
`

interface GetNominalCodesResponse{
  allNominalCode: {
    count: number
    nominalCodes: {
      id: number
      name: string
    }[]
  }
}

const NominalCodes: React.FC = () => {
  const {loading, error, data} = useQuery<GetNominalCodesResponse>(GET_NOMINAL_CODES_QUERY)

  if(loading){
    return <>Loading Nominal Codes</>
  }

  if(error){
    throw error
  }

  const {count, nominalCodes} = data!.allNominalCode

  return <>
    <p>Nominal Codes ({count})</p>
    <Table>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {nominalCodes.map((code) => {
          return <tr key={code.id}>
            <Cell>{code.name}</Cell>
          </tr>
        })}
      </tbody>
    </Table>
  </>
}

const CONTACTS_QUERY = gql`
  query ContactsQuery{
    allContact{
      count
      contacts{
        id
        name
        isSupplier
        isCustomer
      }
    }
  }
`

interface ContactsResponse{
  allContact: {
    count: number
    contacts: {
      id: number
      name: string
      isSupplier: boolean
      isCustomer: boolean
    }[]
  }
}

const Contacts: React.FC = () => {
  const {loading, error, data} = useQuery<ContactsResponse>(CONTACTS_QUERY)

  if(loading){
    return  <>Loading Contacts</>
  }

  if(error){
    throw error
  }

  const {count, contacts} = data!.allContact

  return <>
    <p>Contacts ({count})</p>
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Customer</th>
          <th>Supplier</th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact) => {
          return <tr key={contact.id}>
            <Cell>{contact.name}</Cell>
            <Cell>{contact.isCustomer ? 'Yes' : 'No'}</Cell>
            <Cell>{contact.isSupplier ? 'Yes' : 'No'}</Cell>
          </tr>
        })}
      </tbody>
    </Table>
  </>
}