import React, {useState, useReducer} from 'react'
import gql from 'graphql-tag'
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import {Link} from '@reach/router'
import {asyncForEach, indexedBy} from '@arcath/utils'
import {faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useForm} from 'react-hook-use-form'

import {PageLoader} from '../../components/elements/loader'

import {Page, Box, Heading} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'
import {Button} from '../../components/blocks/button'
import {Input, SCNInput, Submit} from '../../components/blocks/form'

const INCOMING_SOCK_QUERY = gql`
  query IncomingStock{
    importablePurchaseOrder{
      number
      contact
      lines
      xeroUUID
    }

    allPurchaseOrder(scope: RECEIVABLE){
      count
      purchaseOrders{
        id
        orderNumber
        supplier{
          id
          name
        }
        lines{
          quantity
          description
          serialNumbered
          product{
            id
            name
            productType{
              id
              name
            }
          }
          items{
            id
            received
            stockControlNumber
            purchasePrice
          }
        }
      }
    }
  }
`

interface IncomingStockResponse{
  importablePurchaseOrder: {
    number: string
    contact: string
    lines: number
    xeroUUID: string
  }[]
  allPurchaseOrder: {
    count: number
    purchaseOrders: PurchaseOrder[] 
  }
}

interface PurchaseOrder{
  id: number
  orderNumber: string
  supplier: {
    id: number
    name: string
  }
  lines: {
    quantity: number
    description: string
    serialNumbered: boolean
    product: {
      id: number
      name: string
      productType: {
        id: number
        name: string
      }
    }
    items: {
      id: number
      received: boolean
      stockControlNumber: string
      purchasePrice: number
    }[]
  }[]
}

const IMPORT_PO_MUTATION = gql`
  mutation ImportPO($number: String!){
    importPurchaseOrder(number: $number){
      id
    }
  }
`

const RECEIVE_ITEM_MUTATION = gql`
  mutation ReceiveItem($item: ID!, $stockControlNumber: String!){
    receiveItem(id: $item, stockControlNumber: $stockControlNumber){
      id
    }
  }
`

const FIND_UUID_QUERY = gql`
  query FindPOUUID($number: String!){
    poNumberAsUUID(number: $number)
  }
` 

export const IncomingStock: React.FC<{path: string}> = () => {
  const {loading, error, data, refetch} = useQuery<IncomingStockResponse>(INCOMING_SOCK_QUERY)
  const [importPO] = useMutation(IMPORT_PO_MUTATION)

  if(loading){
    return <PageLoader title={"Incoming Stock"} message={"Loading Purchase Orders"} />
  }

  if(error){
    throw error
  }

  const {importablePurchaseOrder, allPurchaseOrder} = data!
  const {purchaseOrders} = allPurchaseOrder

  return <Page>
    <Heading text="Incoming Stock" actions={[
      {title: 'Refresh', color: 'green-300', onClick: () => refetch()}
    ]} />
    <Box cols={1} rows={2}>
      <h3>About</h3>
      <p>Pipeline contains all none imported POs that have a status of <i>Approved</i> on Xero.</p>
      <p>Manual allows you to manually import a PO from Xero using its PO number.</p>
    </Box>
    <Box cols={3}>
      <h3>Pipeline</h3>
      <Table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Supplier</th>
            <th>Lines</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {importablePurchaseOrder.map((importable) => {
            return <tr key={importable.number}>
              <Cell><a href={`https://go.xero.com/Accounts/Payable/PurchaseOrders/Edit/${importable.xeroUUID}`} target="_BLANK" rel="noopener noreferrer">{importable.number}</a></Cell>
              <Cell>{importable.contact}</Cell>
              <Cell>{importable.lines}</Cell>
              <Cell>
                <Button color={`green-300`} onClick={async () => {
                  await importPO({variables: {number: importable.xeroUUID}})

                  refetch()
                }}>Import</Button>
              </Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
    <Box cols={3}>
      <ManualImport refetch={refetch} />
    </Box>
    {purchaseOrders.map((purchaseOrder) => {
      return <IncomingPurchaseOrder purchaseOrder={purchaseOrder} key={purchaseOrder.id} refetch={refetch} />
    })}
    <ManualStockEntry />
  </Page>
}

interface ItemState{
  items: StateItem[]
}

interface StateItem{
  id: number
  stockControlNumber: string
  received: boolean
  barcodeValid: boolean
  barcodeChecking: boolean
}

const itemReducer = (state: ItemState, change: StateItem) => {
  const newItems = state.items.map(({id, stockControlNumber, received, barcodeValid, barcodeChecking}) => {
    if(id !== change.id){
      return {id, stockControlNumber, received, barcodeValid, barcodeChecking}
    }

    return {id, stockControlNumber: change.stockControlNumber, received, barcodeValid: change.barcodeValid, barcodeChecking: change.barcodeChecking}
  })

  

  return {items: newItems}
}

const IncomingPurchaseOrder: React.FC<{purchaseOrder: PurchaseOrder, refetch: () => void}> = ({purchaseOrder, refetch}) => {
  const initialState = {
    items: purchaseOrder.lines.reduce((items, line) => {
      return items.concat(line.items.map(({id, received}) => {
        return {id, stockControlNumber: '', received, barcodeValid: true, barcodeChecking: false}
      }))
    }, ([] as StateItem[]))
  }

  const [expanded, setExpanded] = useState(false)
  const [state, dispatch] = useReducer(itemReducer, initialState)
  const [receiveItem] = useMutation(RECEIVE_ITEM_MUTATION)

  if(!expanded){
    return <Box cols={4}>
      PO {purchaseOrder.orderNumber} ({purchaseOrder.supplier.name})
      <button className={`float-right`} onClick={() => setExpanded(true)}>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
    </Box>
  }

  const stateItems = indexedBy('id', state.items)

  return <>
    <Box cols={4}>
      PO {purchaseOrder.orderNumber}
      <button className={`float-right`} onClick={() => setExpanded(false)}>
        <FontAwesomeIcon icon={faChevronUp} />
      </button>

      <Table>
        <tbody>
          {purchaseOrder.lines.map((line, i) => {
            return <>
              <tr key={`line-${i}`}>
                <Cell rowSpan={line.quantity + 1} header>
                  <Link to={`/product_types/${line.product.productType.id}/${line.product.id}`} tabIndex={-1}>{line.product.name}</Link><br />{line.description}<br />
                  &pound;{line.items[0].purchasePrice} ex VAT<br/>
                  <br />
                  <Button color={`yellow-300`} onClick={() => {
                    line.items.forEach((item) => {
                      if(!item.stockControlNumber){
                        dispatch({
                          id: item.id,
                          stockControlNumber: `${purchaseOrder.orderNumber}-${i}`,
                          received: false,
                          barcodeValid: true,
                          barcodeChecking: false
                        })
                      }
                    })
                  }}>Auto-Code</Button>
                </Cell>
                <Cell header>Stock Control Number</Cell>
              </tr>
              {line.items.map((item) => {
                if(item.received){
                  return <tr>
                    <Cell>
                      Item {item.stockControlNumber} already received.
                    </Cell>
                  </tr>
                }

                return <tr>
                  <Cell>
                    <SCNInput stockControlNumber={stateItems[item.id].stockControlNumber}
                      onChange={({stockControlNumber, valid}) => {
                        dispatch({
                          id: item.id,
                          stockControlNumber,
                          received: item.received,
                          barcodeValid: valid,
                          barcodeChecking: false
                        })
                      }}
                    />
                  </Cell>
                </tr>
              })}
            </>
          })}
        </tbody>
      </Table>

      <Button 
        color={`yellow-300`}
        onClick={async () => {

          await asyncForEach(state.items, async ({id, stockControlNumber, received, barcodeValid}) => {
            if(!received && stockControlNumber !== '' && barcodeValid){
              await receiveItem({variables: {item: id, stockControlNumber}})
            }
          })

          refetch()
        }}
      >
        Receive Items
      </Button>
    </Box>
  </>
}

const PRODUCT_AUTO_COMPLETE_QUERY = gql`
  query SearchProducts($name: String!){
    allProducts(name: $name){
      count
      products{
        id
        name
      }
    }
  }
`

interface ProductAutoCompleteResponse{
  allProducts: {
    count: number
    products: {
      id: number
      name: string
    }[]
  }
}

const MANUAL_ADD_ITEM_MUTATION = gql`
  mutation AddItem($product: ID!, $stockControlNumber: String!, $description: String!){
    addItem(product: $product, stockControlNumber: $stockControlNumber, description: $description){
      id
    }
  }
`

const ManualStockEntry: React.FC = () => {
  const [productSearch, setProductSearch] = useState('')
  const [product, setProduct] = useState(0)
  const {loading, error, data, refetch} = useQuery<ProductAutoCompleteResponse>(PRODUCT_AUTO_COMPLETE_QUERY, {
    variables: {
      name: 'INITIALQUERYTHATSHOULDSHOWNORESULTS'
    }
  })
  const [description, setDescription] = useState('')
  const [stockControlNumber, setStockControlNumber] = useState('')
  const [addItem] = useMutation(MANUAL_ADD_ITEM_MUTATION)
  const [valid, setValid] = useState(true)


  if(error){
    throw error
  }

  const results = () => {
    if(product !== 0){
      return
    }

    if(loading){
      return <>Searching</>
    }

    return <>
      {data!.allProducts.products.map((product) => {
        return <p onClick={() => {
          setProductSearch(product.name)
          setProduct(product.id)
        }}>
            {product.name}
          </p>
      })}
    </>
  }

  return <Box cols={4}>
    <h3>Manual Stock Entry</h3>

    <Page>
      <div className={`col-span-1`}>
        <Input disabled={product !== 0} value={productSearch} placeholder={`Product`} onChange={(e: any) => {
          setProductSearch(e.target.value)
          refetch({
            name: e.target.value
          })
        }} />
        {results()}
      </div>
      <div className={`col-span-1`}>
        <Input placeholder="Description" disabled={product === 0} value={description} onChange={(e: any) => {
          setDescription(e.target.value)
        }} />
      </div>
      <div className={`col-span-1`}>
        <SCNInput id="manualInputBarcode" placeholder="Stock Control Number" disabled={product === 0} stockControlNumber={stockControlNumber} onChange={({stockControlNumber, valid}) => {
          setStockControlNumber(stockControlNumber)
          setValid(valid)
        }} />
      </div>
      <div className={`col-span-1`}>
        <Button fullWidth color={`green-300`} onClick={async () => {
          if(!valid || stockControlNumber === ''){
            return
          }

          await addItem({variables: {
            product,
            stockControlNumber,
            description
          }})
          setProduct(0)
          setDescription('')
          setStockControlNumber('')
          setProductSearch('')
        }}>Add</Button>
        <Button fullWidth color={`green-600`} onClick={async () => {
          if(!valid || stockControlNumber === ''){
            return
          }

          await addItem({variables: {
            product,
            stockControlNumber,
            description
          }})
          setStockControlNumber('')

          document.getElementById('manualInputBarcode')!.focus()
        }}>Add &amp; Resuse details</Button>
        <Button fullWidth color={`yellow-300`} onClick={async () => {
          await refetch({name: 'INITIALQUERYTHATSHOULDSHOWNORESULTS'})
          setProduct(0)
          setDescription('')
          setStockControlNumber('')
          setProductSearch('')
        }}>Clear</Button>
      </div>
    </Page>
  </Box>
}

const ManualImport: React.FC<{refetch: () => void}> = ({refetch}) => {
  const {formBind, bind, onSubmit} = useForm({
    number: ''
  })
  const {query, mutate} = useApolloClient()

  onSubmit(async ({number}) => {
    let result: any

    try{
      result = await query({
        query: FIND_UUID_QUERY,
        variables: {number}
      })
    }catch(e){
      console.log('Error')
      return
    }

    await mutate({
      mutation: IMPORT_PO_MUTATION,
      variables: {number: result.data.poNumberAsUUID}
    })

    refetch()
  })

  return <>
    <h3>Manual Import</h3>
    <form {...formBind()}>
      <Page>
        <div className="col-span-3">
          <Input placeholder={"PO-XXXX"} {...bind('number')} />
        </div>
        <div className="col-span-1">
          <Submit label="Import" />
        </div>
      </Page>
    </form>
  </>
}