import React from 'react'
import {Link} from '@reach/router'
import {Helmet} from 'react-helmet'

export const Page: React.FC = ({children}) => {
  return <div className={`grid grid-cols-4 gap-4`}>
    {children}
  </div>
}

export const Box: React.FC<{cols: number, rows?: number, classes?: string}> = ({children, cols, rows, classes}) => {
  return <div className={`col-span-${cols} row-span-${rows ? rows : 'auto'} shadow-lg border border-gray-100 p-2 rounded-lg ${classes}`}>{children}</div>
}

export const StatBox: React.FC<{cols: number, rows?: number, title: string, stat: number, to?: string}> = ({title, cols, rows, stat, to}) => {
  return <Box cols={cols} rows={rows}>
    {to ? <Link to={to} className={`text-2xl leading-tight`}>{title}</Link> : <p className={`text-2xl leading-tight`}>{title}</p> }
    <p className={`text-s leading-tight`}>{stat}</p>
  </Box>
}

export const Heading: React.FC<{text: string, actions: {onClick?: () => void, to?: string, title: string, color: string}[]}> = ({text, actions}) => {
  return <>
    <Helmet title={`Warehouse / ${text}`} />
    <div className={`col-span-2`}>
      <h1 className={`text-xl`}>{text}</h1>
    </div>
    <div className={`col-span-2`}>
      {actions.map((action, i) => {
        if(action.to){
          return <Link key={`action-${i}`} to={action.to} className={`float-right bg-${action.color} py-1 px-2 rounded-sm shadow-sm hover:shadow-none mt-1 ml-2 text-gray-800`}>
            {action.title}
          </Link>
        }

        return <button key={`action-${i}`} onClick={action.onClick} className={`float-right bg-${action.color} py-1 px-2 rounded-sm shadow-sm hover:shadow-none mt-1 ml-2 text-gray-800`}>
          {action.title}
        </button>
      })}
    </div>
  </>
}