import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {Link} from '@reach/router'

import {Page, Heading, Box} from '../../components/layout/page'
import {Table, Cell} from '../../components/layout/table'

import {PageLoader} from '../../components/elements/loader'


const SUPPLIERS_QUERY = gql`
  query SupplierssQuery{
    allContact(type: SUPPLIER){
      count
      contacts{
        id
        name
        purchaseOrders{
          count
        }
      }
    }
  }
`

interface SupplierResult{
  allContact: {
    count: Number
    contacts: {
      id: number
      name: string
      purchaseOrders: {
        count: number
      }
    }[]
  }
}

export const SuppliersList: React.FC<{path: string}> = () => {
  const {loading, error, data} = useQuery<SupplierResult>(SUPPLIERS_QUERY)

  if(loading){
    return <PageLoader message="Loading Suppliers" title="Supplierss" />
  }

  if(error){
    throw error
  }

  const suppliers = data!.allContact.contacts

  return <Page>
    <Heading text={`Suppliers (${data!.allContact.count})`} actions={[]} />
    <Box cols={4}>
      <Table>
        <thead>
          <tr>
            <th>Supplier</th>
            <th>Purchase Orders</th>
          </tr>
        </thead>
        <tbody>
          {suppliers.map((supplier) => {
            return <tr key={supplier.id}>
              <Cell><Link to={`/suppliers/${supplier.id}`}>{supplier.name}</Link></Cell>
              <Cell>{supplier.purchaseOrders.count}</Cell>
            </tr>
          })}
        </tbody>
      </Table>
    </Box>
  </Page>
}